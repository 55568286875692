import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "../../partials/Alert";

const EditEventForm = () => {
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("danger");
  const [event, setEvent] = useState("");
  const [impact, setImpact] = useState("");
  const [sourceIP, setSourceIP] = useState("");
  const [sourcePort, setSourcePort] = useState("");
  const [destinationIP, setDestinationIP] = useState("");
  const [destinationPort, setDestinationPort] = useState("");
  const [customerName, setCustomerName] = useState("viperlineoffice");
  const [analystName, setAnalystName] = useState("");
  const [eventSeverity, setEventSeverity] = useState(0);
  const [resolved, setResolved] = useState(0);
  const [allCustomers, setAllCustomners] = useState([]);
  const [allAnalysts, setAllAnalysts] = useState([]);
  const [timestamp, setTimestamp] = useState(
    new Date().toISOString().substring(0, 16)
  );
  const [timestampend, setTimestampEnd] = useState(
    new Date().toISOString().substring(0, 16)
  );
  const [showTimestampEnd, setShowTimestampEnd] = useState(false);

  const { eventId } = useParams();
  const navigate = useNavigate(); // Instantiate useHistory hook

  useEffect(() => {
    const token = window.localStorage.getItem("accessToken");
    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/authenticated/events/${eventId}`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          console.log(response, "response......")
          setEvent(response?.data?.data?.Event);
          setImpact(response?.data?.data?.Impact);
          setSourceIP(response?.data?.data?.SourceIP);
          setSourcePort(response?.data?.data?.SourcePort);
          setDestinationIP(response?.data?.data?.DestinationIP);
          setDestinationPort(response?.data?.data?.DestinationPort);
          setCustomerName(response?.data?.data?.CustomerName);
          setAnalystName(response?.data?.data?.AnalystName);
          setEventSeverity(response?.data?.data?.EventSeverity);
          setResolved(response?.data?.data?.Resolved);
          // const formattedTimestamp = new Date(
          //   response.data.data.DisplayTimestamp
          // )
          //   .toISOString()
          //   .substring(0, 16);
          // setTimestamp(formattedTimestamp);
          const formattedTimestampend = new Date(
            response.data.data.DisplayTimestampEnd
          )
            .toISOString()
            .substring(0, 16);
          // if (
          //   response?.data?.data?.DisplayTimestampEnd !== "2006-01-01T00:00:00Z" && response?.data?.data?.DisplayTimestampEnd !== "2006-01-01 00:00:00" && response?.data?.data?.DisplayTimestampEnd !==  "2006-01-01T06:00:00" && response?.data?.data?.DisplayTimestampEnd !== "2005-12-31T18:30"
          // ) {
          //   console.log("here id")
          //   setShowTimestampEnd(true);
          //   setTimestampEnd(formattedTimestampend);
          // } else {
          //   console.log("here elese....")
          //   setShowTimestampEnd(false);
          //   setTimestampEnd("2006-01-01T00:00")
          // }
          const allowedTimestamps = [
            "2006-01-01T00:00:00Z",
            "2006-01-01 00:00:00",
            "2006-01-01T06:00:00",
            "2005-12-31T18:30:00",
          ];
          
          if (!allowedTimestamps.includes(response?.data?.data?.DisplayTimestampEnd)) {
            console.log("here id");
            setShowTimestampEnd(true);
            setTimestampEnd(formattedTimestampend);
          } else {
            console.log("here else....");
            setShowTimestampEnd(false);
            // setTimestampEnd("2006-01-01T00:00");
            setTimestampEnd(new Date().toISOString().substring(0, 16));
          }
          
        })
        .catch(function (error) {
          setAlertMsg(error.message);
          setDisplayAlert("block");
          console.log(error);
        });
    }
  }, [eventId]);

  const handleFormSubmit = async () => {
    const timesTampCopy = timestamp;
    const timesTampCopyEnd = timestampend;
    const eventData = {
      event,
      impact,
      sourceIP,
      sourcePort: sourcePort.toString(),
      destinationIP,
      destinationPort: destinationPort.toString(),
      customerName,
      analystName,
      timestamp: timesTampCopy + ":00",
      // timestampend: showTimestampEnd ? timesTampCopyEnd + ":00" : timestampend,
      timestampend: showTimestampEnd === true
      ? timestampend.replace("T", " ").replace("Z", "") + ":00"
      : "2006-01-01 00:00:00",
      eventSeverity,
      resolved,
    };

    const token = window.localStorage.getItem("accessToken");

    if (token) {
      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/v1/authenticated/events/${eventId}`,
          eventData,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          // navigate("/Analyst/Import");
          navigate("/Analyst/Index");
        })
        .catch(function (error) {
          console.log(error);
          setAlertMsg(
            "Only the analyst who created the event can edit it. Please contact the event creator to edit this event. (Error: " +
              error.message +
              ""
          );
          setDisplayAlert("block");
        });
    }
  };

  return (
    <section className="card-form">
      <div className="container ">
        <div className="row">
        <div className="col-12">
    <div className="card card-log-head my-3">
    <Alert
        type={alertType}
        message={alertMsg}
        show={displayAlert}
        close={() => setDisplayAlert("none")}
      />
      <h4 className="card-header text-center card-log">Edit Event</h4>
      <div className="card-body card-log-body">
        <p className="card-log-body text-center ">Use this form to edit the event.</p>
        <br/>
        <p className="log-form-head">Event:</p>
        <input
          className="form-control"
          type="text"
          value={event}
          onChange={(e) => setEvent(e.target.value)}
        />
        <br/>
        <p className="log-form-head">Impact:</p>
        <textarea
          className="form-control"
          value={impact}
          onChange={(e) => setImpact(e.target.value)}
        />
        <br />
        <p className="log-form-head">Source IP:</p> 
        <input
          className="form-control"
          type="text"
          value={sourceIP}
          onChange={(e) => setSourceIP(e.target.value)}
        />
        <br />
        <p className="log-form-head">Source Port:</p>
        <input
          className="form-control"
          type="text"
          value={sourcePort}
          onChange={(e) => setSourcePort(e.target.value)}
        />
        <br />
        <p className="log-form-head">
        Destination IP:
        </p>
        <input
          className="form-control"
          type="text"
          value={destinationIP}
          onChange={(e) => setDestinationIP(e.target.value)}
        />
        <br />
        <p className="log-form-head">Destination Port:</p>
        <input
          className="form-control"
          type="text"
          value={destinationPort}
          onChange={(e) => setDestinationPort(e.target.value)}
        />
        <br />
        <p className="log-form-head">Customer Name:</p>
        <select
          className="form-control"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        >
          <option value={customerName}>{customerName}</option>
          {/* <option value="tcss">Tuscaloosa County School System</option>
          <option value="mps">Montgomery Public Schools</option>
          <option value="bhm">Birmingham City Schools</option>
          <option value="sev">Sevierville</option>
          <option value="lee">Lee University</option>
          <option value="cle">Cleveland</option>
          <option value="afc">AFC</option>
          <option value="scac">SCAC</option> */}
        </select>
        <br />
        <p className="log-form-head">Analyst Name:</p>
        <select className="form-control" value={analystName}>
          <option value={analystName}>{analystName}</option>
          {/* <option value="">Select your name from the dropdown</option>
                    <option value="amer">Amer</option>
                    <option value="brooke">Brooke</option>
                    <option value="caleb">Caleb</option>
                    <option value="ian">Ian</option>
                    <option value="mo">Mo</option>
                    <option value="nathaniel">Nathaniel</option> */}
        </select>
        <br />
        <div className="timestamp-head">
          <div className="timestamp-cols">
            <p className="log-form-head">Timestamp Start:</p>
            <div className="">
              <input
                className="form-control"
                type="datetime-local"
                value={timestamp}
                onChange={(e) => setTimestamp(e.target.value)}
              />
            </div>
          </div>

          <div className="timestamp-cols">
            <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
            >
        <input
          style={{ marginBottom: 10 }}
          type="checkbox"
          checked={showTimestampEnd}
          onChange={()=>setShowTimestampEnd(!showTimestampEnd)}
          />
          <p 
          style={{ marginTop: "0px", marginLeft: "10px"}}
          className="log-form-head">
            Timestamp End:
            </p>
            </div>
          {showTimestampEnd && (
            <div className="timestamp-cols">
              <div className="">
                <input
                  className="form-control"
                  type="datetime-local"
                  value={timestampend}
                  onChange={(e) => setTimestampEnd(e.target.value)}
                />
              </div>
            </div>
          )}
          </div>
        </div>
        <br />
        <p className="log-form-head">Event Severity:</p>
        <select
          className="form-control"
          value={eventSeverity}
          onChange={(e) => setEventSeverity(parseInt(e.target.value))}
        >
          <option value={3}>Low(3)</option>
          <option value={2}>Medium(2)</option>
          <option value={1}>High(1)</option>
        </select>
        <br />
        <button 
        // className="btn btn-primary mt-2"
        className="btn btn-primary mt-2 log-form-submit"
         onClick={handleFormSubmit}>
          Submit
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </section>
  );
};

export default EditEventForm;
